<template>
    <div class="home"> 
        <Header/> 
        <div class="dashboard-layout">
            <Sidebar />  
            <div class="body">
                  <div class="amendments-container">
                    <h1 style="margin-left: 20px;">{{$t("settings")}}</h1>
                    <div class="support-con">
                        <div class="con1">
                            <h3 :class="{ 'tb-item': true, 'active': activeItem === 'CustomerAddress' }"
                                @click="redirect('CustomerAddress')">{{$t('customer-address')}}</h3>
                            <h3 :class="{ 'tb-item': true, 'active': activeItem === 'BillingAddress' }"
                                @click="redirect('BillingAddress')">{{$t("invoice-address")}}</h3>
                            <h3 :class="{ 'tb-item': true, 'active': activeItem === 'DataAgreement' }"
                                @click="redirect('DataAgreement')">{{$t("data-protection-agreement")}}</h3>
                            <h3 :class="{ 'tb-item': true, 'active': activeItem === 'IDCardLayout' }"
                                @click="redirect('IDCardLayout')">{{$t("id-card")}} Layout-Vorlagen</h3>
                            <!-- <h3 :class="{ 'tb-item': true, 'active': activeItem === 'IdAdditionalFields' }"
                                @click="redirect('IdAdditionalFields')">{{$t("id-card")}} {{$t("additional-fields")}}</h3> -->
                            <h3 :class="{ 'tb-item': true, 'active': activeItem === 'Miscellaneous' }"
                                @click="redirect('Miscellaneous')">{{$t("others")}}</h3>
                        </div>
                        <div class="con1">
                            <router-view/>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue';
import Header from './Header.vue'
import Sidebar from './Sidebar.vue'

import _ from 'lodash'

export default Vue.extend({
    components:{
      Header, Sidebar
    },

    mounted() {
        const subItem = (document.URL).split('/')[4]
        if (subItem != undefined) {
            if (subItem == "customer-address") {
                this.activeItem = "CustomerAddress"
            }
            if (subItem == "billing-address") {
                this.activeItem = "BillingAddress"
            }
            if (subItem == "data-agreement") {
                this.activeItem = "DataAgreement"
            }
            if (subItem == "id-card-layout") {
                this.activeItem = "IDCardLayout"
            }
            if (subItem == "additional-fields") {
                this.activeItem = "IdAdditionalFields"
            }
            if (subItem == "miscellaneous") {
                this.activeItem = "Miscellaneous"
            }
        } else {
            this.activeItem = "CustomerAddress"
        }
    },

    computed: {
        user() {
        return this.$store.getters['auth/getUser']
        },
    },


    data() {
        return {
            // customer: {},
            activeItem: "CustomerAddress",
        }
    },

    methods:{  
        redirect(path) {
            if (!(this.$route.name == path)) {
                this.activeItem = path
            this.$router.push({ name: path} );
            }
        },
     }
});
</script>